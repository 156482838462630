import React from 'react';
import { IFaqArticle } from './faqPage';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import './faqSectionPage.scss';
import { ArticleButton } from './articleButton';
import { FaqContact } from './faqContact';

const FaqSectionPage: React.FC<{
  pageContext: { articles: IFaqArticle[]; locale: string };
}> = ({ pageContext: { articles, locale } }) => {
  const articlesByLocale = articles.filter(
    article => article.language.slice(0, 2) === locale
  )!;

  const renderArticles = () => {
    return articlesByLocale.map(article => (
      <div className='column is-6' key={article._id}>
        <ArticleButton title={article.title} slug={article.slug} />
      </div>
    ));
  };

  return (
    <div id='faq-section'>
      <TitleAndSubtitle
        title={articles[0].section}
        subtitle='Learn more below'
      />

      <div id='articles' className='columns is-multiline main-padding'>
        {renderArticles()}
      </div>
      <FaqContact />
    </div>
  );
};

export default FaqSectionPage;
